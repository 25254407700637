













import MessageResource from '@/types/MessageResource';
import Vue, { PropType } from 'vue';

export default Vue.extend({
  props: {
    message: {
      type: Object as PropType<MessageResource>,
      required: true,
    },
  },
});
